<template>
	<div class="bg_dark bg_intro_2">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf003">
						<div class="intro_logo_title">
							<span class="hide">{{ $language.kakao.title }}</span>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
						</div>
						<p class="copyright">Copyright © MAFI Inc. </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'kakao',
	data: function(){
		return {
			code: this.$route.query.code
			, program: {
				name: this.$language.kakao.auth
				, not_header: true
				, not_footer: true
			}
			, token_info: null
			, kakao_info: null
		}
	}
	, computed: {

		kakao_return_url: function(){

			let domain = process.env.VUE_APP_DOMAIN_M
			let dev = process.env.VUE_APP_DOMAIN_DEV_M
			// let local = 'https://localhost:8080'
			let location = window.location.href
			let url = ''

			console.log('location', location, domain)
			if(location.indexOf(domain) > -1){
				url = domain
				return
			}else{
				url = dev
			}

			return url + '/auth/loginkakao'
		}
	}
	, methods: {
		loginWithSocial: async function(e) {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_login_social
					, data: {
						member_social_certfc_code: e.type
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if (result.success) {
					this.token_info = result.data
					// console.log('this.token_info', this.token_info)

					sessionStorage.removeItem('Error')
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}

					switch (result.data.member_state_code) {
						default:
							this.$bus.$emit('to', {name: 'mafia009'})
							break;
						case 'MB00500001':
							this.$bus.$emit('to', {name: 'sms'})
							break;
						case 'MB00500002':
							this.$bus.$emit('onPin', 'join')
							break;
						case 'MB00500003':
							this.$bus.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500004':
							this.$bus.$emit('to', {name: 'nice'})
							break;
						case 'MB00500005':
							if(result.data.session_token){
								await this.$encodeStorage.setSessionToken(result.data.session_token)
							}
							await this.$encodeStorage.setItem('login_info', e)
							this.onPin('pin_login')
							//this.$bus.$emit('setUser', result.data)
							break;
						case 'MB00500006':
							this.$bus.$emit('to', {name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'MB00500007':
							this.$bus.$emit('to', {name: 'mafia009', params: {type: 'out', msg: result.message}})
							break;
						case 'MB00500008':
							this.$bus.$emit('to', {name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
					}
				} else {
					console.log(result)
					if ((result.code).indexOf('E0014') > -1) {
						await this.$encodeStorage.removeItem('login_info')
						this.$bus.$emit('to', {name: 'mafia009', params: {type: 'auth', msg: result.message}})
					} else {

						switch (result.code) {
							case 'E000500001':
								this.$bus.$emit('to', {
									name: 'mafia009',
									params: {type: 'not', msg: result.message}
								})
								break;
							case 'E000400012':
								this.$bus.$emit('to', {
									name: 'mafia009',
									params: {type: 'stop', msg: result.message}
								})
								break;
							case 'E000400013':
								this.$bus.$emit('to', {
									name: 'mafia009',
									params: {type: 'out', msg: result.message}
								})
								break;
							case 'E000400014':
								this.$bus.$emit('to', {
									name: 'mafia009',
									params: {type: 'sleep', msg: result.message}
								})
								break;
							case 'E001400003':
							case 'E001400004':
							case 'E001400008':
								await this.$encodeStorage.removeItem('login_info')
								throw result.code
							// throw this.$language.auth.expired_auth_login
							default:
								await this.$encodeStorage.removeItem('login_info')
								throw result.message
						}
					}
				}
			} catch (e) {
				console.log(e)
				this.error = e

				// this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
				this.$emit('to', {name: '400', params: {type: e}})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getAccessToken: async function(){
			window.opener.postMessage({
				key: 'kakaoLogin'
				, data: {
					type: 'kakao'
					, access_token: this.$route.query.code
				}
			})
		}
	}

	, created() {
		this.$emit('onLoad', this.program)
		this.getAccessToken()


		/*
		let r = {
			type : 'kakao'
			, access_token: this.$route.query.code
		}

		window.opener.postMessage({
			key: 'event'
			, data: r
		})

		 */
	}
}
</script>